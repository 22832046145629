.prices {
  h1 {
    margin-bottom: 4rem;
  }
  h2 {
    margin-bottom: .5rem;
  }
  table {
    width: 100%;
    margin-bottom: 4rem;
    tr {
      border-top: 1px solid #eee;
    }
    td {
      padding: .5rem 0;
      vertical-align: top;
    }
    .description {
      font-weight: bold;
    }
  }
}