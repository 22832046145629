.as-text {
  background-color: transparent;
  font-family: inherit;
  font-weight: inherit;
  text-transform: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  padding: 0;
  margin: 0;
  border-width: 0;
}

.btn {
  text-indent: 0.25em;
  letter-spacing: 0.25em;
  text-transform: uppercase;
}

input[type=checkbox] {
  float: left;
  margin-right: .5rem;
}
textarea,
select,
input[type=email],
input[type=file],
input[type=text] {
  border: 1px solid #ccc;
  margin-bottom: 1rem;
  width: 100%;
  background-color: #eee;
  padding: .4rem 1rem;
}

label {
  font-size: 10px;
  text-transform: uppercase;
  display: block;
}
.checkbox-group {
  display: flex;
  margin-bottom: 1rem;
  justify-content: start;
  label {
    font-size: $font-size-base;
    text-transform: none;
  }
}
.radio-row {
  float: left;
  margin-right: 2rem;
  label {
    display: inline;
    font-size: $font-size-base;
    text-transform: none;
    margin-right: .5rem;
  }
}