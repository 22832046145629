.courses {
  .hero {
    @extend .full-width-background-image;
    height: 480px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      @extend %hero;
    }
    h2 {
      @extend %hero-tag;
      line-height: 1.1;
      margin-bottom: 0;
      text-transform: none;
    }
    a.btn {
      color: #ffffff;
    }
  }
  .page {
    h1 {
      margin-bottom: 4rem;
    }
  }
  .course-list {
    margin-bottom: 6rem;
  }
  .no-courses {
    text-align: center;
  }
  .course-card {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    margin-bottom: 2rem;
    h2 {
      text-align: left;
      text-overflow: ellipsis; 
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden; 
      height: 2.7rem;
    }
    .btn:disabled {
      background-color: #666666;
    }
    .course-description {
      text-overflow: ellipsis; 
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden; 
      height: 4.5rem;
    }
    .categories {
      margin-bottom: .4rem;
      .category {
        background-color: #ccc;
        text-transform: uppercase;
        padding: .25rem .5rem;
        font-size: 0.8rem;
        font-weight: bold;
      }
    }
  }
}