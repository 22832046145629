.page {
  padding-top: 6rem;
  padding-bottom: 6rem;
  h1 {
    @extend %heading1;
    // text-transform: uppercase;
  }
  h1, h2, h3 {
    text-align: center;
  }
  h1.with-tag {
    @extend %heading2;
    color: $primary;
    text-transform: uppercase;
  }
  .h1-tag {
    @extend .mx-auto;
    text-align: center;
    margin-bottom: 4rem;
    font-family: $headings-font-family;
    // font-style: italic;
    max-width: 700px;
    // font-weight: 500;
    font-size: 2.5rem;
    @include media-breakpoint-down(sm) {
      font-size: 1.8rem;
    }
  }
  .heading-with-line {
    @extend %heading2withline;
  }
}