$video-height: 600px;
$hero-shrink-ratio: .8;
$video-height-mobile: $video-height * $hero-shrink-ratio;
.video-background {
  height: $video-height;
  @include media-breakpoint-down(sm) {
    height: $video-height-mobile;
  }
  position: relative;
  color: #fff;
  .video-cover {
    width: 100vw;
    height: $video-height;
    @include media-breakpoint-down(sm) {
      height: $video-height-mobile;
    }
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    filter: brightness(40%);
  }
  .music-toggle {
    top: 0;
    right: 0;
    position: absolute;
    padding: 1rem;
  }
}