.location {
  h1 {
    margin-bottom: 4rem;
  }
  .address {
    text-align: center;
    strong {
      @extend %heading2;
    }
    img {
      filter: saturate(0);
    }
    margin-bottom: 2rem;
  }
  .gallery {
    // margin-bottom: 4rem;
    .photo {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
}