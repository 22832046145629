html,
body,
#root {
  // min-height: 100vh;
  overflow-x: hidden;
}

img {
  max-width: 100%;
}

.heading-line  {
  width: 200px;
  @extend .mx-auto;
  border-top: 1px solid $secondary;
  opacity: 1;
  margin-bottom: 2rem;
}

$lla-gray: $gray-900;
$lla-beige: #E5DAD7;
$lla-dark-brown: #3A180F;
