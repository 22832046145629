.footer {
  padding: 4rem 0;
  background-color: $lla-dark-brown;
  color: #fff;
  .footer-logo {
    display: block;
    width: 210px;
    max-width: 90%;
    margin-bottom: 1rem;
  }
  .nav-link:first-child {
    padding-top: 0;
  }
  .address strong {
    letter-spacing: $navbar-letter-spacing;
    color: $secondary;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
  .social {
    // text-align:right;
    margin-bottom: 1rem;
    a {
      display: inline-block;
      margin-right: 1rem;
      font-size: 2rem;
    }
  }
  .nav-link:focus {
    color: $secondary;
  }
  a:hover,
  a {
    color: #fff;
    text-transform: uppercase;
    letter-spacing: $navbar-letter-spacing;
  }
  .newsletter {
    // text-align: right;
    h2 {
      font-size: 1rem;
    }
  }
}