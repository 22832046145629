.order {
  h2 {
    margin-top: 3rem;
  }
  .drag-drop-file {
    width: 100%;
    max-width: 100%;
    padding: 2rem;
  }
  form {
    margin-bottom: 6rem;
  }

  .btn {
    @extend .mx-auto;
    display: block;
  }
  .g-recaptcha {
    margin-bottom: 1rem;
  }
}