.home {
  .hero {
    .hero-content {
      @extend .d-flex;
      @extend .align-items-end;
      padding-top: 10rem;
      padding-bottom: 2rem;
      @include media-breakpoint-down(sm) {
        padding-top: 13rem * $hero-shrink-ratio * .7;
        padding-bottom: 2rem * $hero-shrink-ratio;
      }
      .logo {
        width: 72px;
        min-width: 55px;
        @include media-breakpoint-down(sm) {
          width: 55px;
          min-width: 55px;
        }
        img {
          display: block;
          width: 100%;
        }
      }
      .copy {
        padding-left: 1rem;
      }
    }
    h1 {
      @extend %hero;
    }
    h2 {
      @extend %hero-tag;
      line-height: 1.1;
      margin-bottom: 0;
      text-transform: none;
    }
    .tagline {
      max-width: 400px;
      margin-bottom: 2rem;
      @include media-breakpoint-down(sm) {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }
    }
  }
  .underwritters {
    padding-bottom: 4rem;
    .heading-with-line {
      @extend %heading2withline;
    }
    /*
    h2 {
      @extend .mx-auto;
      @extend %heading1;
      text-transform: none;
      font-size: $h3-font-size;
      color: $lla-gray;
      @include media-breakpoint-down(sm) {
        font-size: $h3-font-size * .7;
      }
      max-width: 500px;
      text-align: center;
      margin-bottom: 4rem;
    }
    hr  {
      @extend .heading-line;
    }
    */
    .logos {
      @extend .d-flex;
      @extend .justify-content-center;
      @extend .align-items-center;
      @extend .flex-wrap;
      margin-top: -1rem;
      @include media-breakpoint-up(lg) {
        margin-top: -1.5rem;
      }
      @include media-breakpoint-up(xl) {
        margin-top: -2rem;
      }
      .uw-entry {
        width: 50%;
        padding: 1rem;
        @include media-breakpoint-up(lg) {
          width: 33%;
          padding: 1.5rem;
        }
        @include media-breakpoint-up(xl) {
          width: 25%;
          padding: 2rem;
        }
        img {
          @extend .mx-auto;
          display: block;
          max-width: 100%;
          // filter: saturate(0);
        }
      }
    }
  }
}