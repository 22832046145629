.forms {
  h1 {
    margin-bottom: 2rem;
  }
  h3 {
    margin-top: 3rem;
  }
  .row {
    @extend .justify-content-center;
  }
  .search {
    @extend .ms-auto;
    width: 200px;
    margin-bottom: 4rem;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    input {
      border-width: 0;
      border-bottom: 1px solid $secondary;
    }
  }
  .doc-group {
    margin-bottom: 2rem;
  }
  .doc-row {
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .align-items-center;
    padding: 0 2rem;
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
    .download {
      white-space: nowrap;
      font-size: 32px;
    }
  }
}