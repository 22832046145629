.navbar {
  button.action {
    color: #fff;
    text-transform: uppercase;
    letter-spacing: $navbar-letter-spacing;
    text-indent: $navbar-letter-spacing;
    font-weight: $nav-link-font-weight;
    @include media-breakpoint-up(lg) {
      border-radius: 0;
      margin-top: -16px;
      height: 66px;
      margin-bottom: -16px;
      padding-left: 1.75rem;
      padding-right: 1.75rem;
    }
  }
  .navbar-brand {
    font-family: $headings-font-family;
    img {
      width: 270px;
      @include media-breakpoint-up(lg) { width: 240px; }
      @include media-breakpoint-up(xl) { width: 250px; }
    }
  }
  .nav-link {
    text-transform: uppercase;
    letter-spacing: $navbar-letter-spacing;
  }
  .navbar-toggler {
    border-width: 0;
  }
  .navbar-toggler:focus {
    box-shadow:none;
  }
  #basic-navbar-nav .navbar-nav{
    @extend .align-items-center;
  }
}

@include media-breakpoint-up(lg) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    letter-spacing: 0.05rem;
  }
}
@include media-breakpoint-up(xl) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: .8rem;
    padding-right: .8rem;
    letter-spacing: $navbar-letter-spacing;
  }
}

.navbar.bg-light {
  background-color: $lla-beige !important;
}