.jobs {
  h1 {
    margin-bottom: 4rem;
  }
  h2 {
    margin-bottom: 2rem;
  }
  .job-entry {
    margin-bottom: 4rem;
  }
}