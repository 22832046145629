.report {
  h1 {
    margin-bottom: 4rem;
  }
  h3 {
    text-align: left;
    padding-top: .5rem;
    padding-bottom: 0;
  }
  th {
    text-align: center;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1rem;
  }
  td,
  table {
    border: 1px solid #ccc;
  }
  th,
  td {
    padding: .25rem 1rem;
  }
  .description {
    font-weight: bold;
  }
  .trid-note {
    margin-bottom: 1rem;
  }
  .notes {
    font-size: $font-size-base * 0.7;
  }
  .money {
    text-align: right;
  }
  .report-footer {
    font-size: $font-size-base * 0.7;
    text-align: center;
  }
  .report-header,
  .report-logo {
    display: none;
  }
  table.hide-seller .col-seller {
    display: none;
  }
  table.hide-lender .col-lender {
    display: none;
  }
  .total-row {
    font-weight: bold;
  }
}

@media print {
  body {
    font-size: 8pt;
  }
  .page {
    padding: 0;
  }
  .container {
    max-width: 100%;
  }
  .footer,
  .navbar {
    display: none;
  }
  .report {
    .report-header,
    .report-logo {
      display: block;
    }
    .report-logo {
      width: 120px;
      float: right;
    }
    h1 {
      margin-bottom: 1rem;
      text-align: left;
      font-size: 16pt;
    }
    h3 {
      text-align: left;
      font-size: 9.5pt;
      padding: 0;
    }
    .notes {
      font-size: 6pt;
    }
    .report-footer {
      font-size: 6pt;
    }
    th,
    td {
      padding: 0 .1rem;
    }
    .sidebar {
      flex: 0 0 auto;
      width: 25%;
    }
  }
}