// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";

$font-family-sans-serif: "Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-weight-normal: 400;


$headings-font-family: "alverata-informal", "Times New Roman", Times, serif;
$headings-italic-font-family: "alverata", "Times New Roman", Times, serif;
$primary:       #9A3324;
$secondary:     #F3723E;

$nav-link-font-weight: 700;
$nav-link-font-size: 14px;

$navbar-nav-link-padding-x: 1rem;
$navbar-letter-spacing: 0.1rem;

$btn-font-weight: 700;
$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;

$input-btn-padding-x: 1.5rem;
$input-btn-padding-y: 0.75rem;

$link-decoration: none;

$modal-content-border-radius: 0;