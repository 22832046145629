.team {
  .staff-row {
    @extend .d-flex;
    @extend .flex-wrap;
    @extend .justify-content-center;
  }
  .staff-row.executives {
    background-color: $lla-beige;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  h2 {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
  .team-video {
    position: relative;
    .music-toggle {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 20px;
      font-size: 30px;
      color: #fff;
    }
    video {
      width: 100%;
    }
  }
  .slice-video {
    margin-top: 4rem;
    .video {
      @extend .full-width-video;
    }
  }
  .staff-entry {
    padding: 1rem;
    text-align: center;
    width: 50%;
    @include media-breakpoint-up(sm) { width: 33%; }
    @include media-breakpoint-up(md) { width: 25%; }
    @include media-breakpoint-up(lg) { width: 20%; }

    .name {
      @extend %heading2;
      @extend .d-flex;
      @extend .align-items-center;
      @extend .justify-content-center;
      min-height: 3.375rem;
      a {
        color: $lla-gray;
      }
    }
    .meta {
      margin-bottom: 1rem;
      .job-title {
        font-size: 14px;
      }
      .email {
        font-size: 14px;
      }
    }
    img {
      @extend .mx-auto;
      display: block;
      border-radius: 50%;
      max-width: 72%;
      // @include media-breakpoint-up(md) { filter: saturate(0) }
      margin-bottom: .5rem;
    }
  }
  .staff-row.executives {
    .name {
      a {
      }
    }
    .meta {
      @extend .d-flex;
      @extend .align-items-center;
      @extend .justify-content-center;
      @extend .flex-column;
      min-height: 2.625rem;
      .job-title {
      }
      .email {
      }
    }
  }
}

.staff-modal {
  .close-button {
    float: right;
  }
  h1 {
    font-size: $h2-font-size;
  }
  .job-title {
    @extend %heading2;
  }
  .info {
    margin-bottom: 1rem;
  }
  img {
    display: block;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
  }
  .bio {
    background-color: #fff;
    padding: 2rem;
  }
  
  .modal-body {
    padding: 2rem;
    background-color: $lla-beige;
    .top-row {
      // @extend .d-flex;
      // @extend .justify-content-start;
      // @extend .align-items-center;
      // @extend .flex-wrap;
      margin-bottom: 1rem;
      text-align: center;
    }
  }
  @include media-breakpoint-up(lg) {
    .modal-dialog {
      max-width: 650px;
    }
    img {
      margin: 0 2rem 0 0;
    }
    .modal-body {
      margin: 2rem;
      padding: 4rem;
      .top-row {
        margin-bottom: 4rem;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
    }
}