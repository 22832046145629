.splash {
  video {
    width: 100%;
  }
  .modal-content {
    margin-top: 6rem;
    background-color: transparent !important;
    width: 100%;
  }
  .splash-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 1rem;
  }
  .splash-actions {
    position: absolute;
    padding-right: 1.5rem;
    padding-top: 0rem;
    right: 0;
    display: flex;
    flex-direction: row;
    z-index: 1056;
    // column-gap: 1rem;
    justify-content: flex-end;
    button {
      padding: .5rem;
    }
  }
  .call-to-action {
    text-align: center;
  }
}

.modal-backdrop {
  opacity: 0.9 !important;
}
