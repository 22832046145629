$font-size1: 1rem;
$font-size2: 1.125rem;
$font-size25: 1.75rem;
$font-size3: 2.5rem;

$font-size-hero: 2.875rem;
$font-size-hero-tag: 2.25rem;

%hero {
  font-family: $font-family-sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-size: $font-size-hero;
  @include media-breakpoint-down(sm) {
    font-size: 26px;
  }
}

%hero-tag {
  font-family: $headings-italic-font-family;
  font-style: italic;
  font-weight: 300;
  font-size: $font-size-hero-tag;
  @include media-breakpoint-down(sm) {
    font-size: $font-size2;
  }
}

%heading1 {
  font-family: $headings-font-family;
  // font-weight: 500;
  font-size: $font-size3;
  @include media-breakpoint-down(sm) {
    font-size: $font-size3 * .8;
  }
}

h1 {
  @extend %heading1;
}

%heading2 {
  font-size: $font-size2;
  font-family: $font-family-sans-serif;
  text-transform: uppercase;
  // letter-spacing: $navbar-letter-spacing;
  font-weight: 600;
  @include media-breakpoint-down(sm) {
    font-size: $font-size2 * .8;
  }
}

h2 {
  @extend %heading2;
}

%heading2withline {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 4rem 0;
  h1 {
    font-size: $font-size25;
    margin-bottom: 3rem;
  }
  h2 {
    @extend %heading2;
    margin-bottom: 1rem;
  }
  hr {
    width: 200px;
    @extend .mx-auto;
    border-top: 1px solid $secondary;
    opacity: 1;
    padding: 0;
    margin: 0;
  }
}
